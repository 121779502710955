import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Image from 'gatsby-image'
import SEO from '../components/seo'
// import Vimeo from '@u-wave/react-vimeo'
import useTranslations from "../components/useTranslations"
import locales from "../../config/i18n"

import { LocaleContext } from "../components/layout"
import Link from '../components/localizedLink'
import Icon from '../components/icon'
import ContactForm from '../components/contact-form'
import Mapa from '../components/mapa'

// import video from '../images/paola-carolina.mp4'
// import poster from '../images/gentileschi.jpg'

import imagenContacto from '../images/contacta.jpg'

const IndexPage = ({ data }) => {
  const { locale } = React.useContext(LocaleContext)
  const { menu, ver_coleccion, ver_nueva_coleccion, ver_video } = useTranslations()
  const isES = locale === 'es'
  return (
    <>
      <SEO title={locales[locale].defaultTitle} lang={locale}/>
      <Helmet>

        {/* Global site tag (gtag.js) - Google Ads: 837151511 */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NQ27S9K');`}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-837151511"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []
          function gtag() { dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-837151511');


          window.addEventListener('load',function(){
          if (window.location.pathname == "/gracias") {
             console.log('test event conversion gracias');
             gtag('event', 'conversion', {'send_to': 'AW-837151511/rMMYCKG6jPoCEJfWl48D'});
           }
           
          document.querySelector('[href*="tel:"]').addEventListener('click',function() {
                console.log('test event conversion tel');
                gtag('event', 'conversion', {'send_to': 'AW-837151511/stIvCITTjPoCEJfWl48D'});
              });
          });
          
          `}
        </script>
      </Helmet>
      <Helmet bodyAttributes={{ class: 'home' }} />
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQ27S9K" height="0" width="0" style={{ display:'none', visibility:'hidden' }}></iframe></noscript>
      <main id="main" className="main main--page">
        <div className="main__container">

        {data.allMdx.edges.filter(lang => 
              lang.node.fields.locale === locale && 
              lang.node.frontmatter.title === 'Origen'
            ).map(collection => (
            <div
              key={collection.node.frontmatter.title}
              className="gallery"
            >
              <div className="gallery__item">
                <div className="card card--section card--section-caption-2">
                  <Image className="card__image" sizes={collection.node.frontmatter.banner.childImageSharp.sizes} />
                  <div className="card__caption">
                    <h2 className="card__title">{collection.node.frontmatter.title}</h2>
                    <Link to={`/${collection.node.frontmatter.slug}`} className="card__button button button--action">{ver_nueva_coleccion}</Link>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="gallery">
            <div className="gallery__item">
              <div className="card card--section" style={{ overflow:'visible' }}>
                <div className="card__text-wrap">
                  {isES ? (
                    <>
                     <h2 className="card__title">Atelier de novias y showroom Barcelona</h2>
                     <p>Todos nuestros vestidos son handmade Barcelona, creados y confeccionados en nuestro propio taller de novias. Paola Carolina trabaja para hacer que las mujeres vivan una experiencia única en un día excepcional, mediante un trato personalizado para que encuentren su vestido de novia. Respetamos el concepto de “Slow Fashion” diseñando y confeccionando los vestidos en nuestro Atelier de Barcelona con tejidos de alta calidad de proveedores locales.</p>
                     <Link to={`/${menu.items[1].slug}`} className="card__button button button--action">Ver Atelier</Link>
                    </>
                  ) : (
                    <>
                      <h2 className="card__title">Bridal atelier and showroom in Barcelona</h2>
                      <p>The atelier and the showroom is located in the heart of the Gracia neighbourhood of Barcelona. All of our dresses are handmade Barcelona, created and tailored in our own wedding atelier. Paola Carolina exists to make it possible for women to life an unique experience in their special day, through a customized service so that they can find their wedding dress. We believe in the concept of “slow-fashion”, by designing and making our dresses in our atelier in Barcelona with high-quality fabrics provided by local suppliers.</p>
                      <Link to={`/${menu.items[1].slug}`} className="card__button button button--action">See Atelier</Link>
                    </>
                  )}
                 
                </div>
              </div>
            </div>
          </div>

          {data.allMdx.edges.filter(lang => 
              lang.node.fields.locale === locale && 
              lang.node.frontmatter.title === "Loca de amor"
            ).map(collection => (
            <div
              key={collection.node.frontmatter.title}
              className="gallery"
            >
              <div className="gallery__item">
                <div className="card card--section card--section-caption-2">
                  <Image className="card__image" sizes={collection.node.frontmatter.banner.childImageSharp.sizes} />
                  <div className="card__caption">
                    <h2 className="card__title">{collection.node.frontmatter.title}</h2>
                    <Link to={`/${collection.node.frontmatter.slug}`} className="card__button button button--action">{ver_coleccion}</Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
          
          <div className="gallery" style={{ margin: '2em 0',border:'.5em solid',borderRadius:'.4em' }}>
            <div className="gallery__item">
              <Mapa/>
            </div>
          </div>

          <div className="gallery gallery--columns-2">
            <div className="gallery__item">
              <div className="card card--section">
                <img src={imagenContacto} alt="Imagen contacto"/>
              </div>
            </div>
            <div className="gallery__item">
              <div className="card card--contacto">
                <h2>Contacto<br/>&#8212;</h2>
                <h3 className="card__title">Paola Carolina <i>Atelier Barcelona</i></h3>
                <ContactForm/>
              </div>
            </div>
          </div>

        </div>
      </main>

    </>
  )
} 

export default IndexPage

export const pageQuery = graphql`
  query Index {
    allMdx(
      filter: {
        frontmatter: {type: {eq: "coleccion"}}
      }
    ) {
      edges {
        node {
          fields {
            locale
          }
          frontmatter {
            title
            slug
            banner {
              childImageSharp {
                sizes(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`